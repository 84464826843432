import React from "react"
import GoogleMapReact from "google-map-react"

const GoogleMap = ({ children, ...props }) => (
  <div style={{ width: "100%", height: "50vh" }}>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: "AIzaSyBvfR5GjYpWhniWGmG1pGmmGIcFj7MPS-Y",
      }}
      {...props}
    >
      {children}
    </GoogleMapReact>
  </div>
)

export default GoogleMap
